import { FunctionalComponentProps } from '../../global';
import { mq } from '../../theme';


type Props = FunctionalComponentProps & {
  backgroundProps?: Object;
  overlayColor?: string;
}

const BackgroundOverlay = ({ 
  backgroundProps = {},
  children,
  css,
  overlayColor,
  ...rest
}: Props) => {
  return (
    <div
      css={mq({
        position: 'relative',
      })}
      {...rest}
    >
      {children}
      <div 
        css={{
          position: 'absolute',
          backgroundColor: overlayColor ? overlayColor : 'rgba(29,45,56,0.6)',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        }}
        {...backgroundProps}
      />
    </div>
  );
}

export {
  BackgroundOverlay
}