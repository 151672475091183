import { Theme } from '@emotion/react';
import { FunctionalComponentProps } from '../../global';
import { mq } from '../../theme';

type VideoProps = {
  H264: { path: string },
  H265: { path: string },
  VP9: { path: string },
}

type Props = FunctionalComponentProps & {
  video: VideoProps,
  videoProps?: Object;
  posterUrl: string;
}

const BackgroundVideo = ({ 
  video,
  videoProps = {},
  posterUrl,
  ...rest
}: Props) => {
  return (
    <div
      {...rest}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        poster={posterUrl}
        controls={false}
        css={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        {...videoProps}
      >
        <source src={video.H265.path} type="video/mp4; codecs=hevc" />
        <source src={video.VP9.path} type="video/webm; codecs=vp9,opus" />
        <source src={video.H264.path} type="video/mp4; codecs=avc1" />
      </video>
    </div>
  );
}

export {
  BackgroundVideo
}